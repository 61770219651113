import { Box } from '@mui/material';
import { sanitizeFieldRestProps } from 'react-admin';
import { useRecordContext } from 'react-admin';

// @todo: Updating source does not reset player to new file.

export const AudioField = (props) => {
	const { className, ...rest } = props;
	const record = useRecordContext(props);
	const titleValue = record.title || '';

	return (
		<Box className={className} {...sanitizeFieldRestProps(rest)}
			sx={{
				display: 'inline-block'
			}}
			>
			<div>{titleValue}</div>
			<audio controls preload="meta">
				<source type="audio/mp4" src={record.src} />
			</audio>
		</Box>
	);
}

export default AudioField;
