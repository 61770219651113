import React from 'react';
import { List, Datagrid, TextField, EditButton, FilterButton, CreateButton, TopToolbar, ReferenceField, ReferenceInput, AutocompleteInput } from 'react-admin';

/*
const PersonaFilters = [
	<TextInput source="q" label="Search" alwaysOn />,
	<TextInput source="name" label="Name" />,
];
*/

const ListActions = () => (
	<TopToolbar>
		<CreateButton />
		<FilterButton />
	</TopToolbar>
);

const eventFilterQuery = searchText => ({ name: `%${searchText}%` });

const formFilters = [
	<ReferenceInput label="Events" source="event_id" reference="events" alwaysOn>
		<AutocompleteInput source="name" filterToQuery={eventFilterQuery} />
	</ReferenceInput>
];

const FormEntryList = (props) => (
	<List
		{...props}
		actions={<ListActions />}
		filters={formFilters}
		>
		<Datagrid rowClick="edit">
			<ReferenceField source="event_id" reference="events" sortBy="event.name" />
			<TextField source="name" />
			<TextField source="email" />
			<EditButton />
		</Datagrid>
	</List>
);

export default FormEntryList;
